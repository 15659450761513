/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from "react";
import { UserProps, generateName } from "../Profile/helpers";
import {
  SymanticTBody,
  Selector,
  SymanticTD,
  SymanticTDNameColumn,
  SymanticTR,
  ProfileList,
  CenterBlock,
  CheckBoxWrapper,
} from "./ProfileSelector.styles";
import { FormattedMessage } from "react-intl";
import { SiteSettings } from "common/utils/holvikaari";
import { CheckBox, Icon } from "@netmedi/frontend-design-system";
import { InitialismIconStatic } from "../Profile/InitialsIcon";
import { useSelector } from "react-redux";
import { endpoints, post } from "common/utils/api";
import { getUser } from "common/actions/user";
import { User } from "common/models/user";
import { RootState, useDispatch } from "store";
import { resetState } from "shared/actions/app";
import { profileWasSelected } from "./helpers";
import { useHistory } from "react-router";
import { useErrorBoundary } from "common/utils/errorBoundary";
import { SimpleNavBar } from "../Profile/Caregivers/SimpleNavBar";

// Profile rows:
const ProfileRow = (
  patient: UserProps,
  callback: any,
  indexKey = "",
  extraInfo = "",
) => {
  const IconColumn = (user: UserProps, callback: any, indexKey: string) => (
    <SymanticTD>
      <input
        type="radio"
        id={indexKey}
        name="profileId"
        value={user.id}
        onClick={e => {
          e.preventDefault();
          callback();
        }}
      />
      <InitialismIconStatic user={user} />
    </SymanticTD>
  );

  const NameColumn = (user: UserProps, extraInfo = "") => (
    <SymanticTDNameColumn>
      <span>{generateName(user)}</span>
      {extraInfo !== "" ? (
        <span>
          &nbsp;(
          <FormattedMessage id={extraInfo} />
          )&nbsp;
        </span>
      ) : (
        ""
      )}
    </SymanticTDNameColumn>
  );

  const ArrowColumn = () => (
    <SymanticTD className="chevron">
      <Icon name="arrowhead_right_16px" size="medium" />
    </SymanticTD>
  );

  return (
    <SymanticTR
      htmlFor={indexKey}
      id={`label${patient.id}`}
      key={indexKey}
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === "Enter") {
          e.preventDefault();
          callback();
        }
      }}
    >
      {IconColumn(patient, callback, indexKey)}
      {NameColumn(patient, extraInfo)}
      {ArrowColumn()}
    </SymanticTR>
  );
};

export const ProfileSelector = () => {
  const user = useSelector((state: RootState) => state.user);
  const { showBoundary } = useErrorBoundary();

  const history = useHistory();

  const [rememberProfile, setRememberProfile] = useState<boolean>(false);

  const dispatch = useDispatch();

  const changeProfile = (profileId: number) => {
    const rememberProfileId = rememberProfile ? profileId : null;

    return post<User>(
      endpoints.caregivees.switchAccount(profileId, rememberProfileId),
      {},
      {
        responseHandler: _res => {
          dispatch(resetState());
          dispatch(getUser());
          return true;
        },
      },
    );
  };

  const changeProfileAndRedirect = async (profileId: number) => {
    try {
      await changeProfile(profileId);
      profileWasSelected();
      // Navigate to home because the user has probably changed
      history.push("/");
    } catch {
      showBoundary(new Error("Profile change failed"));
    }
  };

  return (
    <>
      <SimpleNavBar user={user} />
      <CenterBlock>
        <ProfileList>
          <Selector id="profileSelector">
            <FormattedMessage
              id="people.selector"
              values={{ product_name: SiteSettings.product_name }}
              tagName="h3"
            />
            <SymanticTBody>
              {ProfileRow(
                user.selectable_profiles[0],
                () =>
                  changeProfileAndRedirect(
                    Number(user.selectable_profiles[0].id),
                  ),
                `profileId${user.id}`,
                "people.you",
              )}

              {user.selectable_profiles
                .slice(1)
                .map((profile, index) =>
                  ProfileRow(
                    profile,
                    () => changeProfileAndRedirect(Number(profile.id)),
                    `profile${index}`,
                  ),
                )}
            </SymanticTBody>
            <CheckBoxWrapper>
              <CheckBox
                id="profileSelectorRememberMe"
                name="profileSelectorRememberMe"
                label={
                  <FormattedMessage
                    id="people.remember_profile"
                    tagName="span"
                  />
                }
                checked={rememberProfile}
                onChange={() => setRememberProfile(!rememberProfile)}
              />
            </CheckBoxWrapper>
          </Selector>
        </ProfileList>
      </CenterBlock>
    </>
  );
};
