import { isEmpty, uniqBy, every } from "lodash";
import { Action, Reducer, AnyAction, combineReducers } from "redux";
import { UrgentTask } from "hcp/models/urgentTask";
import { defaultIssuesShape, Issues } from "hcp/models/issues";
import { FeedAction, UpdateAction, RequestAction } from "hcp/actions/feed";
import { UpdateIssueAction } from "hcp/actions/staff";
import { SetUserAction } from "common/actions/user";
import { sortByPriority } from "common/utils/general";

const newUpdateActions = {
  UPDATE_NEW_MESSAGES: "newMessages",
  UPDATE_NEW_NOTIFICATIONS: "newNotifications",
  UPDATE_NEW_FORM_NOTIFICATIONS: "newFormNotifications",
  UPDATE_NEW_VALUE_NOTIFICATIONS: "newValueNotifications",
} as const;

// whitelisted actions for issuelists, mapped to internal state issue list names
const updateActions = {
  ...newUpdateActions,
  UPDATE_ASSIGNED_TO_ME: "assignedToMe",
  UPDATE_ASSIGNED_TO_OTHERS: "assignedToOthers",
  UPDATE_DONE_ISSUES: "doneIssues",
} as const;
export type UpdateActionTypes = keyof typeof updateActions;

const requestActions = {
  REQUEST_NEW_MESSAGES: "newMessages",
  REQUEST_NEW_NOTIFICATIONS: "newNotifications",
  REQUEST_NEW_FORM_NOTIFICATIONS: "newFormNotifications",
  REQUEST_NEW_VALUE_NOTIFICATIONS: "newValueNotifications",
  REQUEST_ASSIGNED_TO_ME: "assignedToMe",
  REQUEST_ASSIGNED_TO_OTHERS: "assignedToOthers",
  REQUEST_DONE_ISSUES: "doneIssues",
} as const;
export type RequestActionTypes = keyof typeof requestActions;

export type IssueListType =
  | "newMessages"
  | "newNotifications"
  | "newFormNotifications"
  | "newValueNotifications"
  | "assignedToMe"
  | "assignedToOthers"
  | "doneIssues";

type ClearIssueListAction = AnyAction & {
  listType: IssueListType;
  setLoading?: boolean;
};

export type State = {
  newMessages: Issues;
  newNotifications: Issues;
  newFormNotifications: Issues;
  newValueNotifications: Issues;
  assignedToMe: Issues;
  assignedToOthers: Issues;
  doneIssues: Issues;
  role: string | null;
  current_user_id: string | null;
  hcpTeamIds: number[];
};
const initialStates: State = {
  newMessages: defaultIssuesShape,
  newNotifications: defaultIssuesShape,
  newFormNotifications: defaultIssuesShape,
  newValueNotifications: defaultIssuesShape,
  assignedToMe: defaultIssuesShape,
  assignedToOthers: defaultIssuesShape,
  doneIssues: defaultIssuesShape,
  role: null,
  current_user_id: null,
  hcpTeamIds: [],
};

// needed by Tagger, to track updated issues' state
const updatedIssues = (state = {}, action: AnyAction): Record<string, any> => {
  switch (action.type) {
    case "UPDATE_ISSUE":
    case "UPDATE_ISSUE_ERROR":
      return {
        ...state,
        [action.data.id]: action.data,
      };
    default:
      return state;
  }
};

// extends Reducer so the typing works in RootState
interface IssueListReducer<A extends Action = AnyAction> {
  (state: State, action: A): State;
}

type IssueListAction = FeedAction | UpdateIssueAction | SetUserAction;

// the main reducer
const issueLists: IssueListReducer = (
  state = initialStates,
  action: IssueListAction,
) => {
  if (isUpdateAction(state, action)) {
    if (action.append ?? true) {
      return appendToIssueList(state, action);
    } else {
      return setIssueList(state, action);
    }
  } else if (isRequestAction(state, action)) {
    return requestIssueListUpdate(state, action);
  } else if (action.type === "CLEAR_ISSUE_LIST") {
    return clearIssueList(state, action as ClearIssueListAction);
  } else if (action.type === "UPDATE_ISSUE") {
    return handleSingleIssueUpdate(state, action);
  } else if (action.type === "SET_USER") {
    return setUserInfo(state, action);
  }
  return state;
};

const urgentTasks = (
  state: UrgentTask[] = [],
  action: AnyAction,
): UrgentTask[] => {
  switch (action.type) {
    case "GET_URGENT_TASKS":
      return state.concat(action.data);
    default:
      return state;
  }
};

const isUpdateAction = (
  _: any,
  action: IssueListAction,
): action is UpdateAction => {
  return action.type in updateActions;
};

const isRequestAction = (
  _: any,
  action: IssueListAction,
): action is RequestAction => {
  return action.type in requestActions;
};

type Issue = Issues["issues"][number];

const filterUniqByLists = (
  data: Issues["issues"],
  lists: (keyof State)[],
  state: State,
): Issues["issues"] =>
  data.filter(issue =>
    every(
      lists,
      list =>
        !(state[list] as Issues)?.issues?.find(
          (item: Issue) => item.id === issue.id,
        ),
    ),
  );

const getFilteredIssues = (
  action: UpdateAction,
  state: State,
): Issues["issues"] => {
  const { data } = action;
  if (action.type in newUpdateActions) {
    return filterUniqByLists(data, filterList as (keyof State)[], state);
  }
  return data;
};

const filterList = ["assignedToMe", "assignedToOthers", "doneIssues"];

const setIssueList: IssueListReducer<UpdateAction> = (state, action) => {
  const issueListName = updateActions[action.type];
  const data = getFilteredIssues(action, state);
  const meta = isEmpty(action.meta) ? defaultIssuesShape["meta"] : action.meta;

  return {
    ...state,
    [issueListName]: {
      issues: data,
      loading: false,
      meta,
    },
  };
};

const appendToIssueList: IssueListReducer<UpdateAction> = (state, action) => {
  const issueListName = updateActions[action.type];
  const data = getFilteredIssues(action, state);
  const meta = isEmpty(action.meta) ? defaultIssuesShape["meta"] : action.meta;

  return {
    ...state,
    [issueListName]: {
      issues: uniqBy(
        state[issueListName].issues.concat(data).sort(issueCompare),
        "id",
      ),
      loading: false,
      meta,
    },
  };
};

const requestIssueListUpdate: IssueListReducer<RequestAction> = (
  state,
  action,
) => {
  const issueListName = requestActions[action.type];
  return {
    ...state,
    [issueListName]: {
      ...state[issueListName],
      loading: true,
    },
  };
};

const clearIssueList: IssueListReducer<ClearIssueListAction> = (
  state,
  action,
) => {
  const issueListName = action.listType;
  const loading = action.setLoading ?? false;

  return {
    ...state,
    [issueListName]: {
      ...state[issueListName],
      ...(loading !== undefined ? { loading } : {}),
      issues: [],
    },
  };
};

const handleSingleIssueUpdate: IssueListReducer = (state, action) => {
  const issue = action.data;
  if (issue.state === "unassigned") {
    // early bail just in case
    return state;
  }
  return {
    ...state,
    newMessages: remove(state.newMessages, issue),
    newNotifications: remove(state.newNotifications, issue),
    newFormNotifications: remove(state.newFormNotifications, issue),
    newValueNotifications: remove(state.newValueNotifications, issue),
    assignedToMe: isAssignedToMyself(issue, state)
      ? appendAndSort(state.assignedToMe, issue)
      : remove(state.assignedToMe, issue),
    assignedToOthers: isAssignedToSomeoneElse(issue, state)
      ? appendAndSort(state.assignedToOthers, issue)
      : remove(state.assignedToOthers, issue),
    doneIssues:
      issue.state === "done"
        ? appendAndSort(state.doneIssues, issue)
        : remove(state.doneIssues, issue),
  };
};

const remove = (state: Issues, issue: Issues["issues"][number]): Issues => {
  const removed = state.issues.find(item => item.id === issue.id);
  return {
    ...state,
    issues: state.issues.filter(item => item.id !== issue.id),
    meta: {
      ...state.meta,
      total: removed ? state.meta.total - 1 : state.meta.total,
    },
  };
};

const isAssignedToMyself = (issue: any, state: State) => {
  if (issue.state !== "assigned") {
    return false;
  }
  if (issue.assigned_role) {
    return issue.assigned_role === state.role;
  }
  if (issue.assigned_team) {
    return state.hcpTeamIds.includes(issue.assigned_team.id);
  }
  return issue.assignee.id === state.current_user_id;
};

const isAssignedToSomeoneElse = (issue: any, state: State) => {
  if (issue.state !== "assigned") {
    return false;
  }
  if (issue.assigned_role) {
    return issue.assigned_role !== state.role;
  }
  if (issue.assigned_team) {
    return !state.hcpTeamIds.includes(issue.assigned_team.id);
  }
  return issue.assignee.id !== state.current_user_id;
};

const appendAndSort = (
  state: Issues,
  issue: Issues["issues"][number],
): Issues => ({
  ...state,
  issues: uniqBy(state.issues.concat(issue).sort(issueCompare), "id"),
  meta: {
    ...state.meta,
    total: state.meta.total + 1,
  },
});

export const issueCompare = <T extends Record<string, any>>(a: T, b: T) =>
  sortByPriority(b.priority, a.priority);

const setUserInfo: IssueListReducer<SetUserAction> = (state, { data }) => ({
  ...state,
  role: data.role_id ?? null,
  current_user_id: data.id?.toString() ?? null,
  hcpTeamIds: data.hcp_team_ids ?? [],
});

export default combineReducers({
  updatedIssues,
  issueLists: issueLists as Reducer<State>,
  urgentTasks,
});
