import { get, post, put, del, endpoints } from "common/utils/api";
import { parseJson } from "common/utils/json_api";
import { IssueUpdateErrorProps } from "shared/components/Modal/IssueUpdateError";
import { State as PopulationDashboardState } from "hcp/reducers/PopulationDashboard";
import { Id } from "common/utils/types";

// used in feed and app reducers
export type UpdateIssueErrorAction = {
  type: "UPDATE_ISSUE_ERROR";
  data: IssueUpdateErrorProps["issue"];
  issueAction: IssueUpdateErrorProps["issueAction"];
};

// used in feed reducer
export type UpdateIssueAction =
  | UpdateIssueErrorAction
  | {
      type: "UPDATE_ISSUE";
      data: any;
    };

// used in PopulationDashboard reducer
export type PopulationDashboardPeopleAction = {
  type: "SET_POPULATION_DASHBOARD_PEOPLE";
  meta: PopulationDashboardState["content"];
} & (
  | { options: { filter: "own" }; data: PopulationDashboardState["ownUsers"] }
  | {
      options: { filter?: undefined };
      data: PopulationDashboardState["allUsers"];
    }
);

// used in people reducer
export type PeopleAction =
  | {
      type: "UPDATE_INVITE";
      data: any;
    }
  | {
      type: "ADD_STAFF";
      client: string;
    }
  | { type: "RM_INVITE"; id: string | number };

// used in client reducer
export type ClientAction =
  | {
      type: "SET_CLIENT_STAFF";
      data: any[];
    }
  | {
      type: "CLEAR_CLIENT";
    };

export type FailedInviteAction = {
  type: "SET_FAILED_INVITES";
  data: any[];
  meta: any;
  links: any;
};

// not used in any reducer?
export type RemoveStaffAction = {
  type: "REMOVE_STAFF";
  client: string;
};

export const getClientStaff = (clientId: Id, own = true) =>
  get(endpoints.client.staff(clientId, own)).then(
    (data): ClientAction => ({
      type: "SET_CLIENT_STAFF",
      data: data,
    }),
  );

const catchFailedIssueModify = (
  data: any,
  issueAction: { name: string; params: any },
): UpdateIssueAction => ({
  type: "UPDATE_ISSUE_ERROR",
  data,
  issueAction,
});

export const assign = (
  { id, updated_at }: any,
  personOrRoleOrTeam?: any,
  extraParams = {},
) =>
  put(endpoints.issues.assign(id), {
    ...personOrRoleOrTeam,
    ...extraParams,
    updated_at,
  })
    .then(
      (data): UpdateIssueAction => ({
        type: "UPDATE_ISSUE",
        ...(parseJson(data, { mapRelations: true }) as any),
      }),
    )
    .catch(error => {
      const { data, res } = error;
      if (data && res && res.status === 409) {
        return catchFailedIssueModify(data, {
          name: "assign",
          params: personOrRoleOrTeam,
        });
      }
      throw error;
    });

const markDoneCallbacks = (
  method: "post" | "put",
  endpoint: string,
  params: any = {},
  actionName: string,
) => {
  const requestMethod = method === "post" ? post : put;

  return requestMethod(endpoint, params)
    .then(
      (data: any): UpdateIssueAction => ({
        type: "UPDATE_ISSUE",
        ...(parseJson(data, { mapRelations: true }) as any),
      }),
    )
    .catch((error: any) => {
      const { data, res } = error;
      if (data && res && res.status === 409) {
        return catchFailedIssueModify(data, { name: actionName, params: {} });
      }
      throw error;
    });
};

// updated_at is only required when with_action = false
export const markDone = (
  { id, updated_at }: { id: string | number; updated_at?: string },
  with_action = false,
) =>
  markDoneCallbacks(
    "put",
    endpoints.issues[with_action ? "done_with_action" : "done"](id.toString()),
    {
      updated_at,
    },
    with_action ? "done_with_action" : "done",
  );

export const markCrcDoneWithSms = ({
  id,
  issuer_id,
}: {
  id: Id;
  issuer_id: string;
}) =>
  markDoneCallbacks(
    "post",
    endpoints.crc_issues.done_with_sms(id, issuer_id),
    {},
    "done_with_sms",
  );

export const clearClient = (): ClientAction => ({ type: "CLEAR_CLIENT" });

export const getPopulationDashboardUsers = (options = {}) =>
  get(endpoints.population_dashboard_users(options)).then(
    (data): PopulationDashboardPeopleAction => ({
      type: "SET_POPULATION_DASHBOARD_PEOPLE",
      options,
      meta: data.meta,
      ...(parseJson(data, options) as any),
    }),
  );

// seemingly not used in any reducers/epics,
// but removing the type breaks this function
type PopulationDashboardDataPointData = {
  type: "SET_POPULATION_DASHBOARD_DATA_POINTS";
  data: {
    form: string;
    field: string;
    content: string;
    text: string;
    caption: string;
    time: string;
  }[];
};

export const getPopulationDashboardDataPoints = (
  symptom: string,
  input_form_answer_ids: (string | number)[],
) =>
  get(
    endpoints.population_dashboard_data_points({
      symptom,
      input_form_answer_ids: input_form_answer_ids.join(","),
    }),
  ).then(
    (data): PopulationDashboardDataPointData => ({
      type: "SET_POPULATION_DASHBOARD_DATA_POINTS",
      ...(parseJson(data) as any),
    }),
  );

export const addStaff = (clientId: Id, staffId: Id) =>
  post(endpoints.updateStaff(clientId), { staff_id: staffId }).then(
    (): PeopleAction => ({
      type: "ADD_STAFF",
      client: clientId.toString(),
    }),
  );

export const removeStaff = (clientId: Id, staffId: Id) =>
  del(endpoints.removeStaff(clientId, staffId))
    .then(
      (): RemoveStaffAction => ({
        type: "REMOVE_STAFF",
        client: clientId.toString(),
      }),
    )
    .catch(({ data }) => {
      throw data.errors;
    });

export const resendInvite = (id: string) =>
  put(endpoints.invites.show(id))
    .then(
      (data): PeopleAction => ({
        type: "UPDATE_INVITE",
        data,
      }),
    )
    .catch(({ data }) => {
      throw data.errors;
    });

export const removeInvite = (id: string) =>
  del(endpoints.invites.show(id)).then(
    (data): PeopleAction => ({
      type: "RM_INVITE",
      id: data.id,
    }),
  );

export const getFailedInvites = (options: any = {}, signal?: AbortSignal) =>
  get(
    options.url ? options.url : endpoints.failedInvites.index(options),
    false,
    signal ? { signal } : {},
  ).then(
    (data): FailedInviteAction => ({
      type: "SET_FAILED_INVITES",
      data: data.data,
      meta: data.meta,
      links: data.links,
    }),
  );
