import Message from "shared/models/message";
import {
  del,
  delWithoutPageLoadError,
  endpoints,
  get,
  getWithoutPageLoadError,
  parseJson,
  post,
  postForm,
} from "common/utils/api";
import { conversationActions } from "shared/reducers/chat/conversations";
import { draftActions } from "shared/reducers/chat/draftMessage";
import { messageActions } from "shared/reducers/chat/messages";
import { File } from "shared/components/Conversation/AttachmentArea";
import { Id } from "common/utils/types";

export { partChat as leaveChat } from "shared/reducers/chat/conversations";

/** Used to hide/show mobile nav when opening/closing mobile keyboard */
export type ShowMobileNavAction = {
  type: "SHOW_MOBILE_NAV";
  data: any;
};

/** Used in the chat epic */
export type OpenChatAction = {
  type: "OPEN_CHAT";
  id: string;
};

const LIMIT = 30;

export const postMessage = (
  conversationId: string,
  message: Partial<Message>,
) =>
  post(endpoints.sendMessage(conversationId), message).then(data =>
    messageActions.addMessage(parseJson(data).data as any),
  );

export const loadMoreMessages = messageActions.startLoadingMessages;

export const postDraft = (conversationId: string, message: Partial<Message>) =>
  (message.attachments ? postForm : post)(
    endpoints.draft(conversationId),
    message,
    { abortOnUnload: false },
  ).then(data =>
    draftActions.updateDraft(data ? (parseJson(data).data as any) : {}),
  );

export const getDraft = (conversationId: string) =>
  get(endpoints.draft(conversationId)).then(data =>
    draftActions.setDraft(data ? (parseJson(data).data as any) : {}),
  );

export const clearDraft = () => draftActions.setDraft({});

export const postDraftAttachment = (conversationId: string, file: File) =>
  postForm(
    endpoints.draftAttachment(conversationId),
    {
      attachment: file,
    },
    { abortOnUnload: false },
  ).then(data => draftActions.addDraftAttachment(parseJson(data).data as any));

export const removeDraftAttachment = (
  conversationId: string,
  attachmentId: string,
) =>
  delWithoutPageLoadError(
    endpoints.draftAttachments(conversationId, attachmentId),
    { abortOnUnload: false },
  ).then(() => draftActions.removeDraftAttachment(attachmentId));

export const addMessages = (data: any) =>
  messageActions.addMessages(
    Object.assign(
      { ...(parseJson(data) as any) },
      data.poll ? {} : { allMessagesLoaded: data.data.length < LIMIT },
    ),
  );

export const deleteMessage = (conversationId: string, messageId: string) =>
  del(endpoints.messages(conversationId, messageId)).then(() =>
    messageActions.deleteMessage(messageId),
  );

export const getConversations = (filter: Record<string, unknown> = {}) =>
  getWithoutPageLoadError(endpoints.conversations.index({ ...filter })).then(
    data => conversationActions.setAllConversations(parseJson(data) as any),
  );

const setConversation = (data: any) =>
  conversationActions.setCurrentConversation(parseJson(data) as any);

export const getConversation = (id: string) =>
  getWithoutPageLoadError(endpoints.conversations.show(id)).then(
    setConversation,
  );

export const addParticipant = (conversation: string, person: Id) =>
  post(endpoints.conversations.participants(conversation), {
    person_id: person,
  }).then(setConversation);

export const removeParticipant = (conversation: string, person: Id) =>
  del(endpoints.conversations.participants(conversation, person)).then(
    setConversation,
  );

export const deleteConversation = (conversationId: string) =>
  del(endpoints.conversations.show(conversationId)).then(data =>
    conversationActions.deleteConversation({
      id: conversationId,
      redirect: data.meta.redirect,
    }),
  );

export const getRecent = () =>
  get(endpoints.conversations.recent).then(data =>
    conversationActions.setRecentMessages(parseJson(data) as any),
  );

export const openChat = (id: string): OpenChatAction => ({
  type: "OPEN_CHAT",
  id,
});

export const setUnread = conversationActions.setUnread;

// Doing this synchronously prevents input elements from reacting if soft keyboard is open.
export const showMobileNav = (data: any) =>
  new Promise(res =>
    setTimeout(
      () => res({ type: "SHOW_MOBILE_NAV", data } as ShowMobileNavAction),
      0,
    ),
  );
