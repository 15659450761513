import { get, post, endpoints, delMultiple, del } from "common/utils/api";
import { Caregiver } from "common/models/caregiver";
import { Id } from "common/utils/types";

export type CaregiverAction = {
  type: "SET_CAREGIVERS" | "ADD_CAREGIVERS" | "REMOVE_CAREGIVERS";
  data: Caregiver[];
};

export type CaregiverInviteAction = {
  type: "REVOKE_INVITE" | "ADD_CAREGIVER";
  data: Caregiver;
};

export const getCaregivers = (client_id: Id) =>
  get(endpoints.client.caregivers.index(client_id)).then(
    (data): CaregiverAction => ({
      type: "SET_CAREGIVERS",
      data,
    }),
  );

export const addCaregivers = (id: Id, caregiver_ids: Array<number>) =>
  post(endpoints.client.caregivers.add(id), { caregiver_ids }).then(
    (data): CaregiverAction => ({
      type: "ADD_CAREGIVERS",
      data,
    }),
  );

export const removeCaregivers = (id: Id, caregiver_ids: Array<number>) =>
  delMultiple(endpoints.client.caregivers.removeMultiple(id), {
    caregiver_ids,
  }).then(() => ({
    type: "REMOVE_CAREGIVERS",
    data: caregiver_ids.map(id => ({
      id,
    })),
  }));

export const revokeCaregiverInvite = (caregiver_id: Id) =>
  del(endpoints.caregiver_invites.destroy(caregiver_id)).then(
    (data): CaregiverInviteAction => ({
      type: "REVOKE_INVITE",
      data,
    }),
  );
