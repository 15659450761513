import React from "react";
import {
  AcceptBox,
  BottomDisclaimer,
  TextWrapper,
  CaregiverAcceptContainer,
} from "./ActivateCaregiverRelationship.styles";
import { Button } from "@netmedi/frontend-design-system";
import { endpoints } from "common/utils/endpoints";
import { post } from "common/utils/api";
import { User } from "common/models/user";
import { FormattedMessage, injectIntl } from "react-intl";
import LocalizedMarkdown from "common/components/Markdown/LocalizedMarkdown";
import { SimpleNavBar } from "./SimpleNavBar";

type Props = {
  user: User;
  relationships: any[];
  intl: any;
};

const ActivateCaregiverRelationship = ({ user, relationships }: Props) => {
  const relationship = relationships[0];

  const activate = async () => {
    await post(
      endpoints.client.caregivers.activate(user.id, relationship?.patient_id),
    );
    location.reload();
  };
  const decline = async () => {
    await post(
      endpoints.client.caregivers.decline(user.id, relationship?.patient_id),
    );
    location.reload();
  };

  return (
    <CaregiverAcceptContainer>
      <SimpleNavBar user={user} />
      <AcceptBox>
        <TextWrapper>
          <FormattedMessage
            id={"people.caregivers.activation.title"}
            tagName={"h1"}
          />
          <LocalizedMarkdown
            id={"people.caregivers.activation.content"}
            values={{ patient_name: relationship?.patient_name }}
          />
        </TextWrapper>
        <Button type={"primary"} onClick={activate}>
          <FormattedMessage id={"people.caregivers.activation.accept"} />
        </Button>
        <Button type={"ghost"} onClick={decline}>
          <FormattedMessage id={"people.caregivers.activation.decline"} />
        </Button>
      </AcceptBox>
      <BottomDisclaimer>
        <FormattedMessage id={"people.caregivers.activation.disclaimer"} />
      </BottomDisclaimer>
    </CaregiverAcceptContainer>
  );
};

export default injectIntl(ActivateCaregiverRelationship);
