import styled from "styled-components";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";
import { Button } from "@netmedi/frontend-design-system";
import rtl from "styled-components-rtl";
import { rem } from "polished";

export const AcceptBox = styled.div`
  background-color: ${colors.blue50};
  max-width: 650px;
  @media (min-width: 651px) {
    margin: 0 auto 20px auto;
  }
  margin-bottom: 20px;
  padding: 40px;
`;

export const CaregiverAcceptContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export const TextWrapper = styled.div`
  margin-bottom: 40px;
`;

export const BottomDisclaimer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 20px;
`;

export const NavBar = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 650px) {
    justify-content: space-between;
  }
`;
export const NavBarWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const StyledLogoutButton = styled.div`
  line-height: 2.5;
  font-weight: 550;
  margin: 20px;
  max-width: 200px;
  display: flex;
  justify-content: center;

  @media (max-width: 650px) {
    ${rtl`margin: 10px 0 20px -20px;`}
    font-size: ${rem(14)};
  }
`;

export const LogoWrapper = styled.div`
  width: 200px;
  padding: 20px;
  flex-shrink: 1;
`;

export const RightSideWrap = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 650px) {
    display: none;
  }
`;

export const BurgerMenuContainer = styled.div`
  display: none;
  @media (max-width: 650px) {
    display: block;
  }
`;

export const Menu = styled.div`
  position: absolute;
  background-color: ${colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100;
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const LangButton = styled(Button)<{ selected: boolean }>`
  width: 100%;
  ${({ selected }) => `background-color: ${selected && colors.blue50}`};
  :hover {
    background-color: ${colors.gray50};
    text-decoration: none;
  }
  & * {
    display: flex;
    justify-content: space-between;
  }
`;
