import { defaults } from "common/utils/general";
import { Id } from "./types";

export const redirect = (url: string | URL, force = false) => {
  if (force) {
    window.onbeforeunload = null;
  }

  window.location.assign(url);
};

export const conversationsPath = ({ id }: { id?: Id } = {}) =>
  `/conversations/${defaults(id, "")}`;

export const userPath = (user: { id: Id }, linkToEdit = false) =>
  linkToEdit ? `/people/${user.id}/edit` : `/clients/${user.id}`;

export const inputFormAnswerCreateOrOpenPath = (inputFormId: string | number) =>
  `/input_forms/${inputFormId}/create_answer_or_open_existing`;

export const blueprintPerformPath = (blueprintId: string) =>
  `/blueprints/${blueprintId}/perform`;

export const SiteSettings =
  (window as any).SiteSettings ||
  JSON.parse(
    document.querySelector("meta[name=application-settings]") &&
      (document.querySelector("meta[name=application-settings]") as any)
        .content,
  );

export const defaultLocale =
  window.rails_locale || SiteSettings?.default_locale || "en";

export const screenshotMode = SiteSettings?.screenshot_mode === true;
