import {
  get,
  put,
  post,
  putForm,
  del,
  endpoints,
  parseJson,
} from "common/utils/api";
import { addFlash } from "common/actions/flash";
import { mobileAppDeepLink } from "../utils/mobile";
import store from "../../store";
import { User, UserConsents } from "common/models/user";
import { SiteSettings } from "common/utils/holvikaari";
import { Id } from "common/utils/types";

// used in user and feed reducers
export type SetUserAction = {
  type: "SET_USER";
  data: Partial<User>;
  meta?: Partial<User> & { service_closed?: any };
  features?: Record<string, any>;
};

// used in user reducer
export type UserAction =
  | SetUserAction
  | {
      type: "CHANGE_USER_CONSENT";
      data: UserConsents[number];
    }
  | {
      type: "SET_OFFERABLE_CONSENTS";
      data: User["offerable_consents"];
    }
  | {
      type: "SET_PATIENT_ADVISORY_BOARD";
      data: User["show_patient_advisory_board"];
    };

const setUser = (data: any): SetUserAction => ({
  type: "SET_USER",
  ...(parseJson(data) as any as Omit<SetUserAction, "type">),
});

export const getUser = () => get(endpoints.user).then(setUser);

export const getUserById = (id: Id) =>
  get(endpoints.users.user(id)).then(data => {
    return parseJson(data);
  });

export const updateUser = (id: Id, data: any) =>
  put(endpoints.users.user(id), data)
    .then(setUser)
    .catch(({ data }) => {
      throw data.errors;
    });

export const changeUserConsent = (id: Id, data: any) =>
  post(endpoints.users.allConsents(id), data)
    .then(parseJson)
    .then(
      ({ data }: any): UserAction => ({
        type: "CHANGE_USER_CONSENT",
        data,
      }),
    )
    .catch(({ data }) => {
      throw data.errors;
    });

export const extendUser = (data: Partial<User>): SetUserAction => ({
  type: "SET_USER",
  data,
});

export const setLocale = (language: User["language"]): SetUserAction => ({
  type: "SET_USER",
  data: { language },
});

export const updateUserMobileAppFeature = (user: Pick<User, "id">) =>
  updateUser(user.id, {
    features: { ["seen_mobile_app_notification"]: true },
  });

export const updateUserMobileAppDeviceGeneratedDataFeature = (user: User) =>
  updateUser(user.id, {
    features: {
      ["seen_mobile_app_device_generated_data_notification"]: true,
    },
  });

export type CallMobileAppFunctionParams = {
  functionName?: string;
  parameters?: any[];
};

export type CallMobileAppFunctionAction = {
  type: "CALL_MOBILE_APP_FN";
  data: CallMobileAppFunctionParams;
};

export const callMobileAppFunction = (
  data: CallMobileAppFunctionParams,
): CallMobileAppFunctionAction => ({
  type: "CALL_MOBILE_APP_FN",
  data: {
    functionName: data.functionName,
    parameters: data.parameters,
  },
});

export const openMobileAppDeepLink = (user: User) => {
  updateUserMobileAppFeature(user).then(
    () => (window.location.href = mobileAppDeepLink(SiteSettings.site)),
  );
};

export const sendUserMobileAppSms = (user: Pick<User, "id" | "phone">) => {
  put(endpoints.users.sendMobileAppSms)
    .then(() => updateUserMobileAppFeature(user))
    .then(() => {
      const content = document.getElementById("content");
      if (content) content.scrollTop = 0;
      store.dispatch(
        addFlash(
          {
            id: "mobile_app.sms.sent",
            values: { phone_number: user.phone },
          },
          "success",
        ),
      );
    });
};

export const sendUserMobileAppEmail = async () => {
  await put(endpoints.users.sendMobileAppEmail);
};

export const openMobileAppDeviceGeneratedDataSettings = (
  user: User,
  dismiss: (closed?: boolean) => void,
) => {
  updateUserMobileAppDeviceGeneratedDataFeature(user).then(() => {
    store.dispatch(
      callMobileAppFunction({
        functionName: "navigateToDeviceGeneratedDataSettings",
      }),
    );
    dismiss(false);
  });
};

export const updateAvatar = async (id: Id, data: any) => {
  const avatar = await putForm(endpoints.avatar(id), data);
  return setUser(avatar);
};

export const deleteAvatar = (id: Id) =>
  del(endpoints.avatar(id)).then(data => setUser(data));

export const getOfferableConsents = (id: Id) =>
  get(endpoints.consents.offerable(id))
    .then(parseJson)
    .then(({ data }): UserAction => {
      return { type: "SET_OFFERABLE_CONSENTS", data };
    });

export const setPatientAdvisoryBoard = (show: boolean): UserAction => ({
  type: "SET_PATIENT_ADVISORY_BOARD",
  data: show,
});
