import React, { useEffect, useState } from "react";
import {
  NavBar,
  LogoWrapper,
  StyledLogoutButton,
  RightSideWrap,
  NavBarWrapper,
  BurgerMenuContainer,
  Menu,
  LangButton,
} from "./ActivateCaregiverRelationship.styles";
import { FormFieldInput as Input } from "common/components/Form/FormField.styles";
import Logo from "common/components/Logo";
import { Button, Icon } from "@netmedi/frontend-design-system";
import { ButtonTextAfterIcon } from "../Profile.styles";
import { FormattedMessage } from "react-intl";
import { setLocale } from "common/actions/user";
import store from "store";
import { User } from "common/models/user";
import useI18nTranslate from "common/hooks/useI18nTranslate";

interface SimpleNavBarProps {
  user: User;
}

export const SimpleNavBar: React.FC<SimpleNavBarProps> = ({ user }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const t = useI18nTranslate();

  const languageOptions = (user.languages as string[]).map(lang => ({
    label: t(`languages.${lang}`),
    value: lang,
  }));

  const changeLanguage = (locale: User["language"]) => {
    store.dispatch(setLocale(locale));
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 650) {
        setMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [menuOpen]);

  const BurgerMenu = () => {
    return (
      <Button type="ghost" onClick={() => setMenuOpen(!menuOpen)}>
        <Icon name="hamburger" size="medium" />
      </Button>
    );
  };

  const LangMenu = () => {
    return (
      <Menu>
        {languageOptions.map(lang => (
          <LangButton
            key={lang.value}
            type="ghost"
            onClick={() => changeLanguage(lang.value as User["language"])}
            selected={lang.value === user.language}
          >
            <span>{lang.label}</span>
          </LangButton>
        ))}
        <LogOut />
      </Menu>
    );
  };

  const LangSelect = () => {
    return (
      <Input
        as="select"
        value={user.language}
        onChange={(e: any) =>
          changeLanguage(e.target.value as User["language"])
        }
      >
        {languageOptions.map(lang => (
          <option key={lang.value} value={lang.value}>
            {lang.label}
          </option>
        ))}
      </Input>
    );
  };

  return (
    <>
      <NavBarWrapper>
        <NavBar>
          <LogoWrapper>
            <Logo alt="Kaiku Health" />
          </LogoWrapper>
          <RightSideWrap>
            <LangSelect />
            <LogOut />
          </RightSideWrap>
          <BurgerMenuContainer>
            <BurgerMenu />
          </BurgerMenuContainer>
        </NavBar>
      </NavBarWrapper>
      {menuOpen && <LangMenu />}
    </>
  );
};

const LogOut = () => (
  <StyledLogoutButton>
    <a href="/signout">
      <Icon name="logout" size="medium" />
      <ButtonTextAfterIcon>
        <FormattedMessage id="app.sign_out" />
      </ButtonTextAfterIcon>
    </a>
  </StyledLogoutButton>
);
