import React, { useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import AbsenceList from "./AbsenceList";
import AbsenceForm from "./AbsenceForm";
import { AbsencesProvider } from "./useAbsences";
import { AbsencePage } from "./Absences.styles";
import { useDispatch } from "react-redux";
import { getStaff } from "common/actions/staff";
import {
  useErrorBoundary,
  withErrorBoundary,
} from "common/utils/errorBoundary";

export const absenceRoutes = {
  index: "/",
  new: "/new",
  edit: (id?: string | number) => `/edit/${id ?? ":id"}`,
};

const Absences: React.FC = () => {
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  // Fetch staff
  useEffect(() => {
    (async () => {
      try {
        const setStaffAction = await getStaff();
        dispatch(setStaffAction);
      } catch (e: any) {
        showBoundary(e);
      }
    })();
  }, []);

  return (
    <AbsencesProvider>
      <AbsencePage data-testid="Absences">
        <HashRouter>
          <Switch>
            <Route path={absenceRoutes.index} exact>
              <AbsenceList />
            </Route>
            <Route path={absenceRoutes.new}>
              <AbsenceForm />
            </Route>
            <Route path={absenceRoutes.edit()}>
              <AbsenceForm />
            </Route>
          </Switch>
        </HashRouter>
      </AbsencePage>
    </AbsencesProvider>
  );
};

export default withErrorBoundary(Absences);
